<template>
	<v-card
		class="card"
		:class="category.status === CategoryStatus.LOCKED ? 'disabled' : 'active'"
		@click="onCardMobileClick"
	>
		<v-icon v-if="category.status === CategoryStatus.COMPLETED" color="rgb(104, 107, 223)" size="25px" class="iconCard"
			>mdi-checkbox-multiple-marked-circle</v-icon
		>
		<div class="cardInfo">
			<div class="cardInfoAction">
				<h2 class="title">{{ category.name }}</h2>
				<div v-if="category.status === CategoryStatus.LOCKED" class="lockedSession-mobile">
					<v-icon size="20px" class="mr-2 mb-1">mdi-lock-outline</v-icon>
					<span>À débloquer</span>
				</div>
				<div class="exerciseProgress mt-1" v-if="category.status !== CategoryStatus.LOCKED">
					<v-icon v-if="category.status === CategoryStatus.COMPLETED" class="trophy" size="28px" color="#fdc90d"
						>mdi-trophy-variant</v-icon
					>
					<v-progress-linear
						:value="(100 * totalPassedSessions) / totalSessions"
						class="progressBar"
						height="20"
						color="#7854f7"
						background-color="#b3b4fc"
					></v-progress-linear>
					<span class="progress-legends">{{ totalPassedSessions }}/{{ totalSessions }}</span>
				</div>
				<div v-else class="lockedSession mt-1 mb-5">
					<v-icon size="20px" class="mr-3">mdi-lock-outline</v-icon>
					<span>{{ totalSessions }} sessions</span>
				</div>
			</div>
			<v-btn
				class="actionBtn"
				:class="category.status === CategoryStatus.LOCKED ? 'disabledBtn' : 'activeBtn'"
				:disabled="category.status === CategoryStatus.LOCKED ? true : false"
				@click="onBtnClick"
				>{{ btnLabel }}</v-btn
			>
		</div>

		<div class="cardThumbnail">
			<v-img
				class="thumbnail"
				:src="category.status === CategoryStatus.LOCKED ? category.cover_image_locked : category.cover_image"
			>
			</v-img>
		</div>
	</v-card>
</template>

<script>
import { ExerciseStatus } from '@/constants/exercise.js';
import { SessionStatus } from '@/constants/session.js';
import { CategoryStatus } from '../../../../constants/category';
export default {
	name: 'ChapterCard',
	data() {
		return { CategoryStatus, timeoutId: null };
	},
	props: { category: Object },
	computed: {
		btnLabel() {
			if (this.category.status === CategoryStatus.OPEN) return 'COMMENCER';
			if (this.category.status === CategoryStatus.ONGOING) return 'CONTINUER';
			if (this.category.status === CategoryStatus.LOCKED) return 'À DÉBLOQUER';
			if (this.category.status === CategoryStatus.COMPLETED) return 'S’ENTRAINER';

			return '';
		},
		totalPassedSessions() {
			if (this.category.exercises)
				return this.category.exercises.filter((exercise) =>
					[ExerciseStatus.PASSED, ExerciseStatus.OPEN, ExerciseStatus.ONGOING].includes(exercise.status)
				).length;

			// Sessions
			return this.category.sessions.filter((session) =>
				[SessionStatus.PASSED, SessionStatus.OPEN, SessionStatus.ONGOING].includes(session.status)
			).length;
		},
		totalSessions() {
			if (this.category.exercises) return this.category.exercises.length;
			return this.category.sessions.length;
		},
		isMobile() {
			return window.innerWidth <= 600;
		},
	},
	beforeDestroy() {
		if (this.timeoutId) clearTimeout(this.timeoutId);
	},
	methods: {
		onBtnClick() {
			window.scrollTo(0, 0);
			if (this.category.exercises) this.$router.push(`${this.$route.path}/${this.category._id}`);
			else {
				const { parcoursId } = this.$router.currentRoute.params;
				this.$router.push({
					name: 'Topic Dashboard Chapter Detail',
					params: { idParcours: parcoursId, idCategory: this.category._id },
				});
			}
		},

		onCardMobileClick() {
			if (window.innerWidth > 600) return;
			this.onBtnClick();
		},
	},
};
</script>

<style lang="scss" scoped>
.active {
	background-color: #f0f0fc !important;
	.title {
		color: rgb(104, 107, 223);
		margin-bottom: 10px;
	}
}
.disabled {
	pointer-events: none;
	border: solid 1px #f5f5f7;
	cursor: default;
	.title {
		color: #a9a9af;
	}
}

.card {
	padding: 2rem;
	display: flex;
	border-radius: 4px;
	pointer-events: none;
	position: relative;
	cursor: default;
}

.iconCard {
	position: absolute;
	top: 0;
	right: 0;
	margin-right: 10px;
	margin-top: 10px;
}

.cardInfo {
	width: 70%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.title {
		font-weight: bold;
	}
	.actionBtn {
		max-width: 150px;
		font-weight: bold;
		text-transform: capitalize;
		pointer-events: all;
		cursor: pointer;
	}
	.disabledBtn {
		pointer-events: none;
		cursor: default;
	}
	.activeBtn {
		background-color: rgb(104, 107, 223);
		color: white !important;
	}
	.exerciseProgress {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 70%;
		position: relative;
		.progressBar {
			border-radius: 10px !important;
		}
		span {
			font-size: 18px;
			font-weight: bold;
			color: rgb(104, 107, 223);
			padding-left: 10px;
		}
		.trophy {
			z-index: 1;
			position: absolute;
			left: -10px;
			top: -1px;
		}
	}
	.lockedSession {
		display: flex;
		align-items: center;
	}
}

.cardThumbnail {
	width: 30%;
	display: flex;
	align-items: center;
	.thumbnail {
		width: 30%;
	}
}

.lockedSession-mobile {
	display: none;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
	box-shadow: initial;
}

.v-btn--is-elevated {
	box-shadow: initial;
}

@media screen and (max-width: 600px) {
	.disabled {
		.cardInfo {
			background-color: white;
			.title {
				color: #a9a9af;
			}
		}
	}
	.card {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-direction: column-reverse;
		padding: 0;
	}
	.active {
		pointer-events: all;
		cursor: pointer;
	}

	.cardInfo {
		width: 100%;
		align-items: center;
		justify-content: center;
		padding: 1.5rem 2rem 1.5rem 2rem;
		background-color: #f0f0fc;
		border-radius: 4px;

		.cardInfoAction {
			width: 100%;
			text-align: center;
		}
		.title {
			text-align: center;
			color: rgb(104, 107, 223);
		}
		.exerciseProgress {
			width: 100%;
			.progressBar {
				border-radius: 10px !important;
			}
		}
		.lockedSession {
			display: flex;
			align-items: center;
			justify-content: center;
			display: none;
		}

		.progress-legends {
			color: rgb(104, 107, 223) !important;
		}
		.actionBtn {
			display: none;
		}
	}
	.cardThumbnail {
		width: 60%;
		display: flex;
		flex-direction: column;
		padding-top: 1.5rem;
		.thumbnail {
			width: 100%;
		}
	}
	.lockedSession-mobile {
		display: block;
		transform: translateY(10px);
		margin-bottom: 10px;
	}
}
</style>
